<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <div class="tab_navigation">
            <v-tabs v-model="tab">
                <v-tab>Маршруты</v-tab>
                <v-tab>Вагоны</v-tab>
            </v-tabs>
            <vue-add-button @onClick='onAdd'>
                {{tab==0 ? 'Добавить маршрут' : 'Добавить вагон'}} 
            </vue-add-button>
        </div>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <trains-routes ref="trainsRoutes"/>
            </v-tab-item>
            <v-tab-item>
                <trains-form ref="trainsForm"/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import VueAddButton from '@/components/ui/vueAddButton.vue'
import BusesRoute from '@/components/administration/buses/busesRoute.vue'
import TrainsForm from '@/components/administration/trains/trainsForm.vue'
import TrainsRoutes from '@/components/administration/trains/trainsRoutes.vue'
export default {
    components: {
        VueBreadcrumbs,
        VueAddButton,
        BusesRoute,
        TrainsForm,
        TrainsRoutes
    },
    data: () => ({
        tab: 0,
        breadCrumbs: [
            {
                text: 'Заведение блоков',
                to: '/administration/block'
            },
            {
                text: 'Поезда',
                to: '/administration/block/trains',
            }
        ]
    }),
    watch: {
        tab(val) {
            if (val===1) {
                if (this.$refs.trainsForm) {
                    this.$refs.trainsForm.getList()
                }
            } else {
                if (this.$refs.trainsRoutes) {
                    this.$refs.trainsRoutes.getList()
                } 
            }
        }
    },
    methods: {
        onAdd() {
            if (this.tab==0) {
                this.$refs.trainsRoutes.onAdd()
            } else {
                this.$refs.trainsForm.onAdd()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.tab_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
</style>